<template>
  <div>
    <div
      v-if="show"
      class="d-flex justify-content-center align-items-center"
      style="height: 50vh;"
    >
      <b-spinner
        label="Spinning"
      />
    </div>
    <b-card
      v-if="!show"
    >
      <!-- <b-overlay :show="show" class="d-inline-block" opacity="0" color=""> -->
      <b-card style="overflow: hidden">
        <b-row>
          <!-- <b-col
          md="3"
          class="mb-1"
        >
          <b-input-group>
            <b-form-input placeholder="Фильтр" />
            <b-input-group-append>
              <b-button variant="outline-primary">
                Поиск
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col> -->
          <b-col
            md="8"
            class="d-flex justify-content-end mb-1"
          >
            <b-form-select
              v-model="selected"
              :options="options"
              @change="getOrders"
            />

          </b-col>
          <b-col md="2">
            <b-form-datepicker
              id="start"
              v-model="startDate"
              class="mr-1"
              :state="startDate ? true : none"
              locale="en-US"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="От числа"
              @context="onContextStart"
            />
          </b-col>
          <b-col md="2">
            <b-form-datepicker
              id="end"
              v-model="endDate"
              :state="endDate ? true : none"
              locale="en-US"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="До числа"
              @context="onContextEnd"
            />
          </b-col>
        </b-row>

        <b-table
          responsive="sm"
          :items="orders"
          :fields="tableColumns"
          style="width: 100%; overflow: auto"
        >
          <template v-slot:cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary  "
              class="btn-icon mr-1"
              :to="{ name: 'order', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary  "
              class="btn-icon mr-1"
              v-if="data.item.payment_method_id === 1"
              @click="() => log(data.item.payment.id)"
            >
              <b-spinner small v-if="isLoadingPaymentStatus === data.item.payment.id"/>
              <feather-icon v-if="isLoadingPaymentStatus !== data.item.payment.id" icon="RefreshCcwIcon" />
            </b-button>
          </template>

        </b-table>
      </b-card>
      <b-row>
        <b-col>
          <b-pagination
            v-if="rows >= perPage"
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @input="getOrders"
          />
        </b-col>
        <b-col md="3">
          <label
            :style="{ color: activeColor, fontSize: '15px' }"
            for="total"
          >Всего заказов: {{ rows }}</label>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BModal,
  BRow,
  BTable,
  VBModal,
  BCard,
  BFormSelect,
  BPagination,
  BSpinner,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { $themeConfig } from '@themeConfig'
import { codeSeparated } from './Pagination/code'

export default {

  components: {
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BTable,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BPagination,
    BSpinner,
    BFormDatepicker,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      dialogVisible: false,
      orders: [],
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'user.name', label: 'Имя', sortable: true },
        // { key: 'payment_method_id', label: 'Способ оплата', sortable: true },
        { key: 'shipping_type', label: 'Доставка' },
        { key: 'promo_code', label: 'Промо-код' },
        // { key: 'discount', label: 'Скидка' },
        // { key: 'total', label: 'Итог' },
        { key: 'user.address', label: 'Адрес', sortable: true },
        { key: 'user.phone', label: 'Телефон' },
        { key: 'status_name', label: 'Статус', sortable: true },
        {
          key: 'items.length',
          label: 'Кол-товаров',
        },
        {
          key: 'created_at',
          label: 'Дата',
          formatter: value => new Date(value).toLocaleString(),
          sortable: true,
        },
        { key: 'actions', label: 'Действия' },
      ],
      selected: null,
      options: [
        { value: null, text: 'Сортировать' },
        { value: 'new', text: 'Новая заявка' },
        { value: 'reviewing', text: 'В обработке' },
        { value: 'delivering', text: 'Доставка' },
        { value: 'delivered', text: 'Доставка выполнена' },
        { value: 'completed', text: 'Успешно завершено' },
        { value: 'cancelled', text: 'Отмена заявки' },
      ],
      show: true,
      codeSeparated,
      currentPage: 1,
      rows: 50,
      perPage: 13,
      startDate: undefined,
      endDate: undefined,
      isLoadingPaymentStatus: null,
    }
  },
  mounted() {
    this.getOrders(1)
  },
  methods: {
    async log(id) {
      this.isLoadingPaymentStatus = id
      await this.updatePaymentStatus(id)
    },
    showVisible() {
      this.dialogVisible = true
    },
    onContextStart(ctx) {
      // The following will be an empty string until a valid date is entered
      this.startDate = ctx.selectedYMD
      if (this.startDate) {
        axios.get(`${$themeConfig.app.API}v2/admin/orders${this.selected === null ? '?' : `?status=${this.selected}&`}per_page=${this.perPage}&start_date=${ctx.selectedYMD}&end_date=${this.endDate || new Date().toISOString().slice(0, 10)}`).then(res => {
          this.orders = res.data.data
          this.rows = res.data.meta.total
          this.perPage = res.data.meta.per_page
          this.show = false
        }).catch(er => {
          console.log(er)
          this.show = false
        })
      }
    },
    onContextEnd(ctx) {
      // The following will be an empty string until a valid date is entered
      this.endDate = ctx.selectedYMD
      if (this.endDate) {
        axios.get(`${$themeConfig.app.API}v2/admin/orders${this.selected === null ? '?' : `?status=${this.selected}&`}per_page=${this.perPage}&start_date=${this.startDate || this.endDate}&end_date=${ctx.selectedYMD}`).then(res => {
          this.orders = res.data.data
          this.rows = res.data.meta.total
          this.perPage = res.data.meta.per_page
          this.show = false
        }).catch(er => {
          console.log(er)
          this.show = false
        })
      }
    },
    getOrders(page) {
      this.show = true
      axios.get(`${$themeConfig.app.API}v2/admin/orders${this.selected === null ? '?' : `?status=${this.selected}&`}per_page=${this.perPage}&page=${page}`).then(res => {
        this.orders = res.data.data
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.show = false
      }).catch(er => {
        console.log(er)
        this.show = false
      })
    },
    async getStatuses() {
      await axios.get(`${$themeConfig.app.API}v2/admin/orders/${this.$route.params.id}`)
        .then(res => {
          this.statuses = res.data.data || []
        })
    },
    async updatePaymentStatus(paymentId) {
      try {
        const response = await axios.post(`${$themeConfig.app.API}web/payment/check/${paymentId}`)
        if (response.status === 200) {
          this.isLoadingPaymentStatus = null
          this.$bvToast.toast('Статус оплаты обновлен', {
            title: 'Успешно',
            variant: 'success',
            solid: true,
          })
          this.getOrders(this.currentPage)
        }
      } catch (e) {
        this.isLoadingPaymentStatus = null
        this.$bvToast.toast('Ошибка обновления статуса оплаты', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>
